import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import useSWR from 'swr';
import { URLS } from '../../services/urls';
import moment from 'moment';
import ExportExcel from '../../components/download/DownloadExcel';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { confirmDeletion } from '../../components/dletion';
import { useTranslation } from 'react-i18next';
import NewProductForm from './components/NewProduct';
import ManageStockForm from './components/ManageStockForm';
import { DEFAULT_PAGE_COUNT } from '../../components/utils/consts';
import LoaderPage from '../../components/LoaderPage';
import { formatDate, formatNumber, sumArray } from '../../components/utils/functions';
import BonDeSortie from './components/BonDeSortie';
import { DatePicker } from 'react-responsive-calendar-picker';
import SupplyTicket from './components/SupplyTocket';

const OutStockLists = ({ type, title, isSelledHistory, showStockMvt }) => {
  const [selected, setSelected] = useState([]);
  const [search, setSearch] = useState(showStockMvt ? showStockMvt?.code : '');
  const [toEdit, setToEdit] = useState();
  const [toEditStock, setToEditStock] = useState();
  const [selectedInvoice, setSelectedInvoice] = useState();
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [dates, setDates] = useState({
    checkin: new Date(moment().startOf('month').format('YYYY-MM-DD')),
    checkout: new Date(moment().endOf('month').format('YYYY-MM-DD')),
  });

  const { active_agency } = useSelector((state) => state.agencies);

  const {
    data: products,
    isLoading,
    mutate,
  } = useSWR(
    URLS.STOCKS.lists(
      active_agency?.id,
      search,
      page,
      `OUT${isSelledHistory ? '&for_sale=true' : '&for_sale=false'}`,
      dates?.checkout
        ? `&created_at__date__gte=${formatDate(dates.checkin)}&created_at__date__lte=${formatDate(
            dates.checkout
          )}`
        : `&created_at=${formatDate(dates.checkin)}`
    )
  );

  const { t } = useTranslation();

  const handleShow = (item, obj) => {
    let toShow = {};
    toShow[item] = obj;
    setToEdit(toShow);
  };

  const handleChangePage = (event, page) => {
    if (page) {
      console.log(page);
      setPage(page);
    }
  };

  const handleClose = () => {
    window.$('.btn-close')?.click();
  };

  const handleSuccess = () => {
    setToEdit();
    mutate();
  };

  const handleEdit = (obj) => {
    setToEdit(obj);
  };

  const handleDelete = (obj) => {
    confirmDeletion(URLS.PRODUCTS.delete(obj.id), t, mutate);
  };

  useEffect(() => {
    if (showStockMvt) {
      setSearch(showStockMvt?.code);
    }
  }, [showStockMvt]);

  return (
    <div className="row">
      <div className="col-12">
        <div className="=">
          <div className="card-body">
            {!showStockMvt && (
              <div className="d-flex justify-content-between flex-row">
                <div>
                  <p className="text-uppercas h4">{title ?? 'Sorties de stock'}</p>
                  {/* <h3 className="text-uppercase">Historique des sorties de stock </h3> */}
                  {/* <p>Les sorties de stock representent les produits sortis pour utilisation.</p> */}
                </div>
                <div>
                  {!isSelledHistory && (
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light rounded- px-3"
                      data-bs-toggle="modal"
                      data-bs-target=".new-stock-modal-out"
                    >
                      {' '}
                      + Déstockage
                    </button>
                  )}
                </div>
              </div>
            )}

            {/* <p className="card-title-desc">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem aspernatur culpa animi. Veritatis doloremque earum, maiores, laboriosam deserunt voluptas magni provident ratione rerum a quasi quam sunt quisquam illo nam!
              </p> */}

            {/* Manage stock */}

            {!isSelledHistory && (
              <div
                className="modal fade new-stock-modal-out"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="myLargeModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="myLargeModalLabel">
                        {'Nouveau Déstockage'}
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <ManageStockForm
                        handleClose={handleClose}
                        onSuccess={handleSuccess}
                        toEdit={toEditStock}
                        side={'OUT'}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div>
              <div className="row py-2">
                <div className="col-md-3 col-12">
                  <div>
                    <div className="form search-form">
                      <i className="fa fa-search"></i>
                      <input
                        onChange={(e) => setSearch(e.target.value)}
                        type="text"
                        class="form-control form-input py-2"
                        placeholder="Ref produit, Designation, etc ..."
                        value={search}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div>
                    <div className=" position-relative" style={{ zIndex: '1' }}>
                      <DatePicker
                        dates={dates}
                        setDates={setDates}
                        open={open}
                        setOpen={setOpen}
                        normal={true}
                      >
                        <button className="btn btn-primary rounded-" onClick={() => setOpen(!open)}>
                          Date du {dates.checkin && dates.checkin.toLocaleDateString()}{' '}
                          {dates.checkout && 'au'}{' '}
                          {dates.checkout && dates.checkout.toLocaleDateString()}
                        </button>
                      </DatePicker>
                    </div>
                  </div>
                  {/* <div className="d-flex justify-content-end">
                      <ExportExcel
                        label={'Exporter en Excel'}
                        sheetName={`Customers page: ${page}`}
                        columns={type === "SERVICES" ? exportServices : exportColumns}
                        dataToExport={(products?.results ?? []).map((customer) => {
                          return {
                            ...customer,
                            marge: `${customer.price - customer.buy_price}`,
                            created_by: `${customer?.created_by?.first_name} ${customer?.created_by?.last_name}`,
                          };
                        })}
                      />
                    </div> */}
                </div>
              </div>
              <div>
                <div
                  className="modal fade outbox-ticket-modal"
                  tabIndex={-1}
                  role="dialog"
                  aria-labelledby={'ticketSell'}
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id={'ticketSell'}>
                          {'Détails de la vente'}
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <SupplyTicket
                          handleClose={handleClose}
                          onSuccess={handleSuccess}
                          invoice={toEdit}
                          isSelledHistory={true}
                          side="IN"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="py-2">
                <div className="d-flex">
                  <div></div>
                  <div></div>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table align-middle table-nowrap mb-0">
                  <thead className="table-light">
                    <tr>
                      {/* <th className="text-uppercas">Référence</th> */}
                      <th className="text-uppercas">Date </th>
                      <th className="text-uppercas">Désignation</th>
                      {type !== 'SERVICES' ? (
                        <>
                          <th className="text-uppercas">
                            {isSelledHistory ? 'Qtés vendues' : 'Quantité'}
                          </th>
                          <th className="text-uppercas">
                            {isSelledHistory ? "Coût d'achat" : "Prix d'achat"}
                          </th>
                          <th className="text-uppercas">
                            {isSelledHistory ? 'Montant Total' : 'Prix de vente'}
                          </th>
                          <th className="text-uppercas">Marge</th>
                          {/* <th className="text-uppercas">Stock</th> */}
                          {/* <th className="text-uppercas">Emplacement</th> */}
                        </>
                      ) : (
                        <>
                          <th className="text-uppercas">Prix</th>
                        </>
                      )}
                      {!showStockMvt && <th className="text-uppercas">Actions</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {/* {
                        JSON.stringify(_.groupBy(products?.results ?? [], 'designation' ))
                    } */}
                    {Object.keys(_.groupBy(products?.results ?? [], 'designation'))?.map(
                      (item, index) => (
                        <tr key={'itmo-' + index}>
                          <td>
                            {moment(
                              _.groupBy(products?.results ?? [], 'designation')[item][0]?.created_at
                            )?.format('DD/MM/YYYY')}{' '}
                            <span className="ms-3">
                              {moment(
                                _.groupBy(products?.results ?? [], 'designation')[item][0]
                                  ?.created_at
                              ).format('HH:MM')}
                            </span>{' '}
                          </td>
                          <td>{item}</td>
                          <td>
                            <span className="badge badge-soft-success">
                              {sumArray(
                                _.groupBy(products?.results ?? [], 'designation')[item]?.map(
                                  (i) => i.quantity
                                )
                              )}
                            </span>
                          </td>
                          <td>
                            <span className="badge badge-soft-success">
                              {formatNumber(
                                Number(
                                  sumArray(
                                    _.groupBy(products?.results ?? [], 'designation')[item]?.map(
                                      (i) => i.buy_price
                                    )
                                  ) *
                                    sumArray(
                                      _.groupBy(products?.results ?? [], 'designation')[item]?.map(
                                        (i) => i.quantity
                                      )
                                    )
                                ).toFixed(2)
                              )}{' '}
                              FCFA
                            </span>
                          </td>
                          <td>
                            <span className="badge badge-soft-success">
                              {formatNumber(
                                Number(
                                  sumArray(
                                    _.groupBy(products?.results ?? [], 'designation')[item]?.map(
                                      (i) => i.sell_price
                                    )
                                  ) *
                                    sumArray(
                                      _.groupBy(products?.results ?? [], 'designation')[item]?.map(
                                        (i) => i.quantity
                                      )
                                    )
                                ).toFixed(2)
                              )}{' '}
                              FCFA{' '}
                            </span>
                          </td>
                          <td>
                            {
                              // sumArray(_.groupBy(products?.results ?? [], 'designation' )[item]?.map(i => i.sell_price))
                            }
                            <span className="badge badge-soft-success">
                              {formatNumber(
                                Number(
                                  ( sumArray(_.groupBy(
                                   products?.results ?? [], 'designation')[item]?.map(
                                      (i) => i.sell_price
                                    )
                                  ) -
                                  sumArray(_.groupBy(products?.results ?? [], 'designation')[item]?.map(
                                      (i) => i.buy_price
                                    ))) *
                                    sumArray(_.groupBy(products?.results ?? [], 'designation')[item]?.map(
                                      (i) => i.quantity
                                    ))
                                ).toFixed(2)
                              )}{' '}
                              FCFA{' '}
                            </span>
                          </td>
                          {!showStockMvt && (
                            <td>
                              <div className="d-flex gap-2 justify-content-center">
                                <Link
                                  to="#"
                                  //   onClick={() => setSelectedInvoice(item)}
                                  onClick={() =>
                                    handleShow(
                                      item,
                                      _.groupBy(products?.results ?? [], 'designation')[item]
                                    )
                                  }
                                  title="Imprimer"
                                  data-bs-toggle="modal"
                                  data-bs-target=".outbox-ticket-modal"
                                  className="btn btn-primary btn-sm btn-colapsed"
                                >
                                  <i className="ri-printer-line"></i>
                                  <span>Imprimer</span>
                                </Link>
                              </div>
                            </td>
                          )}
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
                <div>
                  <div
                    className="modal fade bon-sortie-modal"
                    tabIndex={-1}
                    role="dialog"
                    aria-labelledby="myLargeInvoicdseModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-lg">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="myLargeInvoicdseModalLabel">
                            {'Facture ' + selectedInvoice?.reference}
                          </h5>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body">
                          <BonDeSortie invoice={selectedInvoice} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>{isLoading && <LoaderPage />}</div>
              </div>
              <div>
                {products?.count > 0 && (
                  <div className="paginations pt-3">
                    <ul className="pagination pagination-rounded">
                      <li>
                        <Link
                          tabIndex={-1}
                          onClick={(event) => handleChangePage(event, page - 1)}
                          className={`pager-prev ${page === 1 ? 'disabled' : ''}`}
                          to="#"
                        ></Link>
                      </li>
                      {new Array(Math.ceil(products.count / DEFAULT_PAGE_COUNT))
                        .fill(3)
                        .map((pag, index) => (
                          <li
                            key={'page' + index}
                            className={`page-item ${page === index + 1 ? 'active' : ''}`}
                            aria-current="page"
                          >
                            <Link
                              className={`pager-number ${
                                page * DEFAULT_PAGE_COUNT === Math.ceil(products.count)
                                  ? 'disabled'
                                  : ''
                              } ${page === index + 1 ? 'active' : ''}`}
                              to="#"
                              tabIndex={-1}
                              onClick={(event) => handleChangePage(event, index + 1)}
                            >
                              <span className="page-link">{index + 1}</span>
                            </Link>
                          </li>
                        ))}
                      <li>
                        <Link
                          tabIndex={-1}
                          className={`pager-next ${page === 1 ? 'disabled' : ''}`}
                          to="#"
                        ></Link>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              {/* <DataTable
                  // ref={tableRef}
                  columns={columns}
                  data={products?.results ?? []}
                  pagination
                  selectableRows
                  onSelectedRowsChange={handleChange}
                  progressPending={isLoading}
                  paginationServer
                  paginationTotalRows={products?.count ?? 0}
                  onChangePage={handlePageChange}
                  paginationRowsPerPageOptions={[20]}
                  paginationComponentOptions={paginationComponentOptions}
                /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OutStockLists;
