import { Link, useNavigate } from "react-router-dom";
import Categories from "../Products/Categories";
import Inventory from "../Products/Inventory";
import OutStockLists from "../Products/OutBox";
import SupplyLists from "../Products/Supply";
import { useState } from "react";
import NewInvoiceForm from "../Invoices/components/NewInvoiceForm";
import StockTransfert from "../Products/StockTransfert";
import NewProductInvoiceForm from "../Invoices/components/ProductInvoice";
import StockMovement from "./componennts/StockMovement";
import SellsHistory from "../Products/SellsHistory";
import PageTitle from "../../components/PageTitle";



const Skocks = () => {
    const [view, setView] = useState("")
    const [label, setLabel] = useState("Produits")

    const navigate = useNavigate();
    const handleSuccess = () => {
        // navigate('/invoices/list');
    }

    const handleSetView = (id, label) => {
        setView(id);
        setLabel(label)
    }

    return (
        <div>
            <div className="row">
                <div className="col-md-6">
                    <PageTitle
                        title={'Stocks'}
                        breadcrumbs={
                            <ol className="breadcrumb mb-0">
                            <li className="breadcrumb-item">
                                <Link to="/">Tableau de bord</Link>
                            </li>
                            <li className="breadcrumb-item">
                                <Link to="/stocks">Stocks</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                {label}
                            </li>
                            </ol>
                        }
                    />
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div>
                        {/* <h4 className="text-uppercas fw-500">Stocks</h4> */}
                        <div className="c-table-card-title">Stocks</div>
                    </div>
                    <div className="py-3 border border-1">
                        <div className="d-flex align-items-start"> 
                            <div className="nav flex-column text-uppercase nav-pills me-3 px-3 " id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                <Link to="#" onClick={() => handleSetView("stock-familly", "Produits")} className="nav-link btn rounded-0 active" id="v-pills-family-tab" data-bs-toggle="pill" data-bs-target="#v-pills-family" type="button" role="tab" aria-controls="v-pills-family" aria-selected="false">Produits</Link>
                                <Link to="#" onClick={() => handleSetView("stock-invoice", "Facturation")} className="nav-link rounded-0 btn rounded-0" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">Facturation</Link>
                                <Link to="#" onClick={() => handleSetView("stock-sells", "Etat des ventes")} className="nav-link btn whitespace-nowrap rounded-0" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">Etat des ventes</Link>
                                <Link to="#" onClick={() => handleSetView("stock-stock-mvt", "Mouvements Du Stock")} className="nav-link btn whitespace-nowrap rounded-0" id="v-pills-stock-mvt-tab" data-bs-toggle="pill" data-bs-target="#v-pills-stock-mvt" type="button" role="tab" aria-controls="v-pills-stock-mvt" aria-selected="false">Mouvements Du Stock</Link>
                                <Link to="#stock-in" onClick={() => handleSetView("stock-in", "Approvisionnements")} className="nav-link btn rounded-0" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">Approvisionnements</Link>
                                <Link to="#" onClick={() => handleSetView("stock-out", "Déstockage")} className="nav-link btn rounded-0" id="v-pills-settings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-settings" type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false">Déstockage</Link>
                                <Link to="#" onClick={() => handleSetView("stock-inventory", "Inventaire")} className="nav-link btn rounded-0" id="v-pills-inventory-tab" data-bs-toggle="pill" data-bs-target="#v-pills-inventory" type="button" role="tab" aria-controls="v-pills-inventory" aria-selected="false">Inventaire</Link>
                                {/* <Link to="#" onClick={() => setView("stock-transferts")} className="nav-link btn rounded-0 whitespace-nowrap" id="v-pills-transfert-tab" data-bs-toggle="pill" data-bs-target="#v-pills-transfert" type="button" role="tab" aria-controls="v-pills-transfert" aria-selected="false">Transferts de stock</Link> */}
                            </div>
                            <div className="tab-content border-start px-3 w-100" id="v-pills-tabContent">
                                <div className="tab-pane fade" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                    <div>
                                        <NewProductInvoiceForm title={"Nouvelle Facturation"} chooseCustomer={true} onSuccess={handleSuccess}/>
                                    </div> 
                                </div>
                                <div className="tab-pane fade " id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                                    <SellsHistory type={"PRODUCTS"} isSelledHistory={true} title={"Etat des ventes"}/>
                                </div>
                                <div className="tab-pane fade " id="v-pills-stock-mvt" role="tabpanel" aria-labelledby="v-pills-stock-mvt-tab">
                                    <StockMovement type={"PRODUCTS"} isSelledHistory={true} title={"Etat des ventes"}/>
                                </div>
                                <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                                    <div className="w-100">
                                        {view === "stock-in" && <SupplyLists type={"PRODUCTS"}/>}
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                                {view === "stock-out" && <OutStockLists type={"PRODUCTS"} title={"Déstockage"} isSelledHistory={false}/>}
                                </div>
                                <div className="tab-pane fade" id="v-pills-inventory" role="tabpanel" aria-labelledby="v-pills-inventory-tab">
                                    <div>
                                        <Inventory />
                                    </div>
                                </div>
                                <div className="tab-pane fade show active" id="v-pills-family" role="tabpanel" aria-labelledby="v-pills-family-tab">
                                    <div>
                                        <Categories type={"PRODUCT"} />
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="v-pills-transfert" role="tabpanel" aria-labelledby="v-pills-transfert-tab">
                                    <div>
                                        <StockTransfert title={"Transferts de Stock"} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Skocks;